import $ from 'jquery';
import _ from 'underscore';

import { formItemSelect } from 'app/legacy/widgets/form';
import { clowFilter } from 'app/legacy/widgets/section';
import { tourAreaDao } from 'app/legacy/dao/tour-area-dao';
import { TourArea } from 'app/legacy/model/tour-area';
import { ListPage } from 'app/legacy/widgets/listpage';
import { tourRunDao } from 'app/legacy/dao/tour-run-dao';
import { settings } from 'app/legacy/settings';

import { reportScheduleDao, ReportScheduleFilterParams } from 'app/report-schedule/dao/ReportScheduleDao';
import {
    ReportInterval, ReportIntervalLabels, ReportTypeLabels, WEEKDAYS,
} from 'app/report-schedule/ReportScheduleConstants';
import { ReportScheduleDetailPage } from 'app/report-schedule/pages/detail-page/ReportScheduleDetailPage';
import { hasPermission, PermissionType } from 'app/PermissionType';
import { ReportScheduleListEntryDto } from 'app/report-schedule/dto/ReportScheduleListEntryDto';
import React, { Component } from 'react';
import { AbstractLegacyPage } from 'app/AbstractLegacyPage';
import { createRoot, Root } from 'react-dom/client';
import { Box } from 'app/BoxWorld';

const { rise, app } = window as any;

type Props = {
    parent: HTMLElement;
}

const URL = '/report-schedule/list';

export class ReportScheduleListPage extends Component<Props> implements AbstractLegacyPage {
    name = URL;

    parent: HTMLElement;

    private filter: ReportScheduleFilterParams = {
        offset: 0,
        limit: 25,
    };

    private customers = [];

    private tourAreas: Array<TourArea> = [];

    private root: Root = null;

    // inherited from ListPage
    private datatable: any;

    // inherited from ListPage
    private renderTable: (data, columns, settings) => void;

    constructor(props) {
        super(props);

        this.parent = props.parent;
    }

    static get permissions() {
        return [
            PermissionType.REPORT_SCHEDULE_VIEW_ADMIN,
            PermissionType.REPORT_SCHEDULE_MODIFY_ADMIN,
        ];
    }

    componentWillUnmount() {
        setTimeout(() => this.root?.unmount());
    }

    render() {
        const list = ListPage.call(this, {
            prefix: 'report-schedule',
            labels: {
                pageHeader: 'Automatischer Berichtversand',
                buttonNew: hasPermission(PermissionType.REPORT_SCHEDULE_MODIFY_ADMIN)
                    ? 'Neuer automatischer Bericht' : null,
            },
            pageName: this.name,
            rpc() {
                return [
                    tourRunDao.getCustomerList(),
                    tourAreaDao.findAll(),
                ];
            },
            refresh: () => {
                this.filterReportSchedules();
            },
        });

        const createPage = async (element) => {
            if (element) {
                element.replaceChildren();
                this.loadPageState();
                await list.create($(element));
            }
        };

        return <div ref={createPage} />;
    }

    // eslint-disable-next-line class-methods-use-this
    savePageState() {
        window.sessionStorage.setItem('wachbuch.report-schedule.filter', JSON.stringify(this.filter));
    }

    loadPageState() {
        this.filter = JSON.parse(window.sessionStorage.getItem('wachbuch.report-schedule.filter')) ?? this.filter;
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    public prepareTable($parent, customers, tourAreas) {
        this.customers = customers;
        this.tourAreas = tourAreas;

        const columns = this.getListTableColumns();
        const tableSettings = this.getTableSettings();

        this.renderTable(null, columns, tableSettings);

        this.root?.unmount();
        this.root = createRoot(document.getElementById('placeholder-filters'));
        this.root.render(<Box>{this.renderFilter()}</Box>);
    }

    // eslint-disable-next-line react/no-unused-class-component-methods,class-methods-use-this
    public showDetailsPage(reportSchedule: ReportScheduleListEntryDto | undefined) {
        rise.Navigation.goToPage(ReportScheduleDetailPage, [reportSchedule?.id]);
    }

    // eslint-disable-next-line class-methods-use-this
    public getListTableColumns() {
        return [
            {
                sTitle: 'Typ',
                sAttrName: 'type',
                mRender(mdata, type, obj) {
                    return ReportTypeLabels[obj.type];
                },
            },
            {
                sTitle: 'Intervall',
                sAttrName: 'interval',
                mRender(mdata, type, obj) {
                    let intervalDetails;

                    if (obj.interval === ReportInterval.DAILY) {
                        intervalDetails = obj.time.toTimeString();
                    } else if (obj.interval === ReportInterval.WEEKLY) {
                        intervalDetails = `${WEEKDAYS[obj.day_of_week]}, ${obj.time.toTimeString()}`;
                    } else if (obj.interval === ReportInterval.MONTHLY) {
                        intervalDetails = `am 1. um ${obj.time.toTimeString()}`;
                    }

                    return `${ReportIntervalLabels[obj.interval]} (${intervalDetails})`;
                },
            },
            {
                sTitle: 'Kunde',
                sAttrName: 'customer_name',
            },
            {
                sTitle: 'Standort',
                sAttrName: 'location_name',
            },
            {
                sTitle: 'Objekt',
                sAttrName: 'object_name',
            },
            {
                sTitle: 'Revier',
                sAttrName: 'tour_area_name',
            },
            {
                sTitle: 'Erstellt am',
                sAttrName: 'created_at',
                mWidth: '80px',
                mRender(mData, type, obj) {
                    return obj.created_at.toFormatString(settings.dateTimeFormat);
                },
            },
            {
                sTitle: 'Geändert am',
                sAttrName: 'modified_at',
                mWidth: '80px',
                mRender(mData, type, obj) {
                    if (type === 'display') {
                        return obj.modified_at.toFormatString(settings.dateTimeFormat);
                    }
                    return obj.modified_at?.getTime();
                },
            },
            {
                sTitle: 'Aktiv',
                sAttrName: 'active',
                mRender(mdata, type, obj) {
                    return obj.active ? 'Ja' : 'Nein';
                },
            },
        ];
    }

    private renderFilter() {
        return (
            <div id="report-schedule-filter-form" className="whiteboard">
                <legend />
                <ul className="inline-list title-row-highlight">
                    <Box>{this.createReportTypeTypeSelector()}</Box>
                </ul>
            </div>
        );
    }

    private createReportTypeTypeSelector() {
        const stateTypes = [
            { name: 'Alle', value: undefined },
            { name: 'Aktiviert', value: true },
            { name: 'Nicht Aktiviert', value: false },
        ];

        const reportScheduleTypes = [
            {
                id: 'ALL',
                name: 'Alle',
                value: undefined,
            },
            ..._.map(ReportTypeLabels, (v, k) => ({
                id: k,
                name: v,
                value: k,
            })),
        ];

        const reportScheduleIntervals = [
            {
                id: 'ALL',
                name: 'Alle',
                value: undefined,
            },
            ..._.map(ReportIntervalLabels, (v, k) => ({
                id: k,
                name: v,
                value: k,
            })),
        ];

        const allTours = { id: null, name: 'Alle' };

        return [
            formItemSelect({
                id: 'type-select',
                required: true,
                label: 'Typ',
                source: reportScheduleTypes,
                valueProp: 'name',
                value: ReportTypeLabels[this.filter.type] ?? reportScheduleTypes[0],
                sort: false,
                selected: (e, reportType) => {
                    if (this.filter.type !== reportType.value) {
                        this.filter.type = reportType.value;
                        this.filterReportSchedules();
                    }
                },
            }),
            formItemSelect({
                id: 'intervall-select',
                required: true,
                label: 'Intervall',
                source: reportScheduleIntervals,
                valueProp: 'name',
                value: ReportIntervalLabels[this.filter.interval] ?? reportScheduleIntervals[0],
                sort: false,
                selected: (e, reportInterval) => {
                    if (this.filter.interval !== reportInterval.value) {
                        this.filter.interval = reportInterval.value;
                        this.filterReportSchedules();
                    }
                },
            }),
            ($parent) => {
                clowFilter({
                    required: true,
                    customers: this.customers,
                    $parent,
                    depth: 3,
                    selectedCustomerId: this.filter.customer_id,
                    selectedLocationId: this.filter.location_id,
                    selectedObjectId: this.filter.object_id,
                    change: (selection) => {
                        let updated = false;
                        if (this.filter.customer_id !== selection.customer) {
                            this.filter.customer_id = selection.customer < 0 ? null : selection.customer;
                            updated = true;
                        }
                        if (this.filter.location_id !== selection.location) {
                            this.filter.location_id = selection.location < 0 ? null : selection.location;
                            updated = true;
                        }
                        if (this.filter.object_id !== selection.object) {
                            this.filter.object_id = selection.object < 0 ? null : selection.object;
                            updated = true;
                        }

                        if (updated) {
                            this.filterReportSchedules();
                        }
                    },
                    attributeNameWorkplaces: 'assigned_workplaces',
                });
            },
            formItemSelect({
                id: 'filter-tour-area-select',
                required: true,
                label: 'Revier',
                source: this.tourAreas,
                valueProp: 'name',
                value: this.filter.tour_area_id
                    ? this.tourAreas.find((tour) => tour.id === this.filter.tour_area_id)
                    : allTours,
                firstElem: allTours,
                sort: true,
                selected: (e, tourArea) => {
                    if (this.filter.tour_area_id !== tourArea.id) {
                        this.filter.tour_area_id = tourArea.id;
                        this.filterReportSchedules();
                    }
                },
            }),
            formItemSelect({
                id: 'filter-activated-select',
                required: true,
                label: 'Zustand',
                source: stateTypes,
                valueProp: 'name',
                value: typeof this.filter.active === 'undefined'
                    ? stateTypes[0]
                    : stateTypes.find((active) => active.value === this.filter.active),
                sort: false,
                selected: (e, stateType) => {
                    if (this.filter.active !== stateType.value) {
                        this.filter.active = stateType.value;
                        this.filterReportSchedules();
                    }
                },
            }),
        ];
    }

    private filterReportSchedules() {
        if ($(this.parent).validate()) {
            this.savePageState();
            this.datatable.fnDraw();
        }
    }

    private getTableSettings() {
        return {
            aaSorting: [
                [0, 'desc'],
            ],
            bServerSide: true,
            fnServerData: async (sSource, aoData, fnCallback) => {
                const d = _.object(_.map(aoData, (oData) => [oData.name, oData.value]));

                const data = await reportScheduleDao.findReportSchedules({
                    ...this.filter,
                    offset: d.iDisplayStart,
                    limit: d.iDisplayLength,
                    sort_column: d.iSortCol_0,
                    sort_direction: d.sSortDir_0,
                });

                fnCallback({
                    aaData: data.rows,
                    iTotalRecords: data.totalCount,
                    iTotalDisplayRecords: data.filteredCount,
                    sEcho: d.sEcho,
                });
            },
        };
    }
}

/* Export Pages */
app.pages.report_schedule.list = ReportScheduleListPage;
app.pagesByPath[URL] = ReportScheduleListPage;
